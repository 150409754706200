import React from "react";
import image9 from "../Image/9.jpg";
import image10 from "../Image/10.jpg";
import image11 from "../Image/11.jpg";
import image12 from "../Image/12.jpg";
import image13 from "../Image/13.jpg";
import image14 from "../Image/14.jpg";
import image15 from "../Image/15.jpg";
import image16 from "../Image/16.jpg";
import image17 from "../Image/17.jpg";
import image18 from "../Image/18.jpg";
import image19 from "../Image/19.jpg";
import image20 from "../Image/20.jpg";
import image21 from "../Image/21.jpg";
import image22 from "../Image/22.jpg";
import image23 from "../Image/23.jpg";
import image24 from "../Image/24.jpg";
import image25 from "../Image/25.jpg";
import image26 from "../Image/26.jpg";
import image27 from "../Image/27.jpg";
import image28 from "../Image/28.jpg";
import image29 from "../Image/29.jpg";
import image30 from "../Image/30.jpg";
import image31 from "../Image/31.jpg";
import image32 from "../Image/32.jpg";
import image33 from "../Image/33.jpg";
import image34 from "../Image/34.jpg";
import image35 from "../Image/35.jpg";
import image36 from "../Image/63.jpg";
import image37 from "../Image/37.jpg";
import image38 from "../Image/65.jpg";
import image39 from "../Image/39.jpg";
import image40 from "../Image/40.jpg";
import image41 from "../Image/41.jpg";
import image42 from "../Image/42.jpg";
import image43 from "../Image/43.jpg";
import image44 from "../Image/44.jpg";
import image45 from "../Image/45.jpg";
import image46 from "../Image/46.jpg";
import image47 from "../Image/47.jpg";
import image48 from "../Image/48.jpg";
import image49 from "../Image/61.jpg";
import image50 from "../Image/50.jpg";
import image51 from "../Image/62.jpg";
import image52 from "../Image/52.jpg";
import image53 from "../Image/53.jpg";
import image54 from "../Image/54.jpg";
import image55 from "../Image/55.jpg";
import image56 from "../Image/56.jpg";
import image57 from "../Image/57.jpg";
import image58 from "../Image/58.jpg";
import image59 from "../Image/59.jpg";
import image60 from "../Image/60.jpg";

const Gallery = () => {
  return (
    <div>
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            <h1 class="text-6xl font-bold title-font mb-4 text-gray-900">
              Ga<span className=" text-orange-400">lle</span>ry
            </h1>
          </div>
          <div class="flex flex-wrap -m-2">
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img alt="gallery" class=" w-full h-full" src={image9} />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image10}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image11}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image12}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image13}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image14}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image15}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image16}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image17}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image18}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image19}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image20}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image21}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image22}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image23}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image24}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image25}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image26}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image27}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image28}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image29}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image30}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image31}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image32}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image33}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image34}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image35}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image36}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image37}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image38}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image39}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image40}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image41}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image42}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image43}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image44}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image45}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image46}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image48}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image49}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image50}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image51}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image52}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image53}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image54}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image55}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image56}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image57}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image58}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image59}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image60}
                />
              </div>
            </div>
            <div class="lg:w-1/4 sm:w-1/2 p-4">
              <div class="flex relative">
                <img
                  alt="gallery"
                  class="w-full h-full object-cover object-center"
                  src={image47}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gallery;
